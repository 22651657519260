<template>
  <div class="exam-finished align-items-center d-flex flex-column justify-content-center pt-5">
    <img src="/assets/dashboard/finished.png" alt="">
    <div v-if="job.assessment.type === 'mcq'" class="text-danger">
      <h3 v-if="job.assessment.pass_score > job.score " class="text-danger text-center"><b>Your score : {{ job.score }}
        <br>
        Unfortunately you did not pass this phase. Apply again after 3 months.</b>
      </h3>
      <h3 v-else class="text-success text-center">
        <b>
          Your score : {{ job.score }} <br>
          Congratulations, you have passed this phase. <br> You will get next phase email within <span class="text-danger fs-4">15-30 minutes.</span> <br> Keep eye on your email (inbox/spam/promotions) for next phase. <br>
      
      </b>
      </h3>


    </div>
    <div v-else>
      <h3><b> Your assessment is now under review. We will let you know if you PASS only.</b></h3>
    </div>
    <!-- <h4 class="text-center">
      Thanks for completing Mediusware <b>{{ job.assessment.title }}</b> test ! <br>
      Keep eye on the Mediusware job board to get further updates.</h4> -->


    <router-link to="/user/examination" class="btn btn-primary my-5" style="padding: 12px 60px">Back to dashboard
    </router-link>
  </div>

</template>

<script>
import axios from "../../axios";
import moment from "moment";

export default {
  name: "ExamFinished",
  data() {
    return {
      job: {
        assessment: {}
      }
    }
  },
  mounted() {
    axios.get(`/assessment/${this.$route.params.uuid}/`).then(data => {
      this.job = data.data
    });
    setTimeout(() => {
      this.$router.push('/user/examination')
    }, 60000);
  },
  filters: {
    date: (value) => {
      return moment(value).format("dd MM yyyy h:m a")
    }
  },

}
</script>

<style scoped>

</style>